<template>
    <div style="font-size: 18px;">
        <div v-if="buyUrls">第一步：请首先订购以下应用：
            <a v-for="(url,idx) in buyUrls" :key="idx" :href="url" target="_blank">应用{{idx+1}}</a>
        </div>
        <div v-if="authUrls">第二步：完成订购后进行以下应用授权：
            <a v-for="(url,idx) in authUrls" :key="idx" :href="url" target="_blank">应用{{idx+1}}</a>
            <!-- <div v-for="(url,idx) in authUrls" :key="idx" @click="auth(url)">应用{{idx+1}}</div> -->
            <!-- 客户端授权流程 怎么跨域获取iframe内容？ -->
            <!-- <el-tabs type="card"  v-model="tabName">
                <el-tab-pane v-for="(url,idx) in authUrls" :key="idx" :label="'应用'+(idx+1)">
                    <AuthIFrame :url="url"></AuthIFrame>
                </el-tab-pane>
            </el-tabs> -->
            <!-- <div @click="open()">授权</div> -->
            <!-- <AuthIFrame url="https://oauth.taobao.com/authorize?response_type=token&client_id=12305250&state=1212&view=web"></AuthIFrame> -->
        </div>
    </div>
</template>
<script>
    import { query } from '@/service/sale';
    // import AuthIFrame from './Auth.vue'
    export default {
        components: {
            // AuthIFrame
        },
        data() {
            return {
                buyUrls: null,
                authUrls: null,
                // tabName:'0'
            }
        },
        created() {
            query({ taobao: { buyUrls: true, authUrls: true } }).then(resp => {
                console.log(resp);
                this.buyUrls = resp.taobao.buyUrls;
                this.authUrls = resp.taobao.authUrls;
            }).catch(err => {
                console.log(err);
                this.$message.error(err.message);
            });
        },
        methods: {
            open(){
                var mywin=window.open('<div>test</div>'); 
            },
            auth(url){
                var mywin=window.open(url); 
                // setInterval(()=>{
                //     console.log(mywin.location.hash);
                // },1000);
                
                mywin.addEventListener('message', (event)=>{
                    console.log(event);
                   
                });
                
            }
        }
    }
</script>